import "./Map.css";
import React from "react";

import 'ol/ol.css';
import OlMap from 'ol/Map';
import View from 'ol/View';
import TileLayer from "ol/layer/Tile";
import BingMaps from "ol/source/BingMaps";
import {linear} from 'ol/easing';

const VIEWS = [
    {
        center:[-1162675.040158836,3254180.786101018],
        zoom: 12,
        direction: [-400,-400]
    },
    {
        center:[2592863.325739324,170282.64878917127],
        zoom: 14,
        direction: [-100,100]
    },
    {
        center:[8377494.623702329,4242832.619422417],
        zoom: 12,
        direction: [400,400]
    },
    {
        center:[16857856.48567698,-2453900.546538655],
        zoom: 15,
        direction: [-50,100]
    },
    {
        center: [1270799.8128062033,6003144.2609445015],
        zoom: 14,
        direction: [-100,0]
    }
]


export default class Map extends React.Component{
    constructor(props) {
        super(props);
        this._animationStarted = false;
        this._lastMajorChange = Date.now();
        this._lastIndex = Math.floor(VIEWS.length * Math.random());
        this.state = {
            olmap: new OlMap({
                layers: [
                    new TileLayer({
                        visible: true,
                        preload: 0,
                        source:new BingMaps({
                            key: "AlM9wsUvy58oj_elg-6MjVodrINa4NzFqYIADCmHMa3qi2yCRFBq_3p65FpR25oj",
                            imagerySet: "AerialWithLabelsOnDemand"
                        })
                    })
                ],
                view: new View({
                    center: VIEWS[this._lastIndex].center,
                    zoom: VIEWS[this._lastIndex].zoom,
                }),
                controls:[]
            })
        };
    }

    _animateFrame(){
        this._animationStarted = true;
        const scale = 2;
        const view = this.state.olmap.getView();
        const center = view.getCenter();
        if( Date.now() - this._lastMajorChange > 10000 ){
            this._lastMajorChange = Date.now();
            this._lastIndex = (this._lastIndex + 1) % VIEWS.length;
            view.setCenter(VIEWS[this._lastIndex].center);
            this._animateFrame();
        } else {
            const viewSettings = VIEWS[this._lastIndex];
            view.animate({
                center: [
                    center[0] + viewSettings.direction[0] * scale,
                    center[1] + viewSettings.direction[1] * scale
                ],
                zoom: viewSettings.zoom,
                duration:1000,
                easing: linear
            });
            setTimeout(this._animateFrame.bind(this), 1000);
        }
    }

    componentDidMount() {
        this.state.olmap.setTarget("map");
        //this.state.olmap.getView().on("change:center", () => console.log(`${this.state.olmap.getView().getCenter()}`))
        if( !this._animationStarted ) this._animateFrame();
    }

    render(){
        return <div id={"map"} className={"map"} />
    }

}