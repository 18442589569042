import React from "react";
import "./Overlay.css";

export default class Overlay extends React.Component{
    constructor(props) {
        super(props);
    }

    render(){
        return <div style={{position: "fixed"}} className={"overlay-text"}>
            <p style={{"margin-bottom":0, "margin-top":0}}>
                LuxGIS
            </p>

            <div className={"timer"}></div>

            <p style={{"font-size": "2vh", "margin-top":"1vh", "margin-bottom":"1vh"}}>
                Don't you worry, good stuff is coming...
            </p>
        </div>
    }
}