import './App.css';
import Map from "./Map";
import Overlay from "./Overlay";

function App() {
    return (
        <div className="App">
            <Overlay />
            <Map />
        </div>
    );
}

export default App;
